@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply h-full scroll-smooth;
  }

  body {
    @apply h-full overflow-x-hidden;
  }

  #__next {
    grid-template-rows: 1fr auto;
    @apply grid h-full;
  }
}

@layer components {
  .container-screen {
    @apply mx-auto max-w-7xl;
  }
}

/*
 * Splide
 */

.splide__pagination__page {
  @apply !bg-white shadow;
}
.splide__pagination__page.is-active {
  @apply !bg-gradient-to-b from-primary-100 to-primary-300;
}

/* parralex */
.custom-img {
  background-image: url('https://assets.tresamigosdevelopment.com/suriname-natural-stone-co-website/parallex.png');
  object-fit: cover;
}
